import { bindActionCreators } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { SubscriptionsApi } from '@wix/ambassador-subscriptions-api/http';
import { BillingSubscriptions } from '@wix/ambassador-billing-subscriptions/http';
import { ThunkMiddlewareOptions } from '../../types/thunk-extra';
import rootReducer, {
  setLanguage,
  setRegionalSettings,
  setUser,
  fetchAllSubscriptions,
  openDetails,
  closeDetails,
  mockSubscriptions,
  openCancelConfirmModal,
  closeCancelConfirmModal,
  confirmCancel,
} from './state';
import { TabState } from '../../types/settings';
import { PAID_PLANS_APP_DEF_ID, STORES_APP_DEF_ID } from './constants';
import { PricingPlanBenefitsServer } from '@wix/ambassador-pricing-plan-benefits-server/http';
import { WixEcommerceSubscriptions } from '@wix/ambassador-wix-ecommerce-subscriptions/http';
import { mySubscriptionsActionItemClick, mySubscriptionsOpened } from '@wix/bi-logger-subscriptions-bm/v2';

interface IEvents {
  tabState: TabState;
}

const createController: CreateControllerFn = async ({ flowAPI, controllerConfig }) => {
  const { setProps, wixCodeApi, appParams } = controllerConfig;
  const { isSSR, isEditor } = flowAPI.environment;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const baseUrl = isSSR ? 'https://www.wix.com' : '';
  const settingsEventsHandler = createEventHandler<IEvents>(publicData);
  const biLogger = flowAPI.bi!;

  biLogger.updateDefaults({
    appInstanceId: appParams.instanceId || '00000000-0000-0000-0000-000000000000', // zero guid is only for tests
    origin: wixCodeApi.window.viewMode,
  });

  return {
    async pageReady() {
      settingsEventsHandler.on('tabState', (tabState: TabState) => {
        setProps({ tabState });
      });

      settingsEventsHandler.onReset(() => {
        setProps({ tabState: TabState.Default });
      });

      const navigateToStores = ({ emptyState = false } = {}) => {
        biLogger.report(mySubscriptionsActionItemClick({ action: 'view_products', emptyState }));
        wixCodeApi.location.navigateTo?.({ pageId: 'shop' });
      };

      const navigateToPricingPlans =
        () =>
        async ({ emptyState = false } = {}) => {
          biLogger.report(mySubscriptionsActionItemClick({ action: 'view_plans', emptyState }));
          const structure = await wixCodeApi.site.getSiteStructure({ includePageId: true });
          const pricingPlansPage = structure.pages.filter(
            ({ applicationId }: any) => applicationId === PAID_PLANS_APP_DEF_ID,
          )?.[0];
          if (pricingPlansPage) {
            wixCodeApi.location.navigateTo?.({ pageId: pricingPlansPage.id });
          }
        };

      // This is a hacky way to know if an app is installed. Look for something better.
      const ppInstalled = wixCodeApi.site.getAppToken ? !!wixCodeApi.site.getAppToken(PAID_PLANS_APP_DEF_ID) : false;
      const storesInstalled = wixCodeApi.site.getAppToken ? !!wixCodeApi.site.getAppToken(STORES_APP_DEF_ID) : false;

      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          ...getDefaultMiddleware<any, ThunkMiddlewareOptions>({
            serializableCheck: {
              isSerializable: () => true,
            },
            thunk: {
              extraArgument: {
                baseUrl,
                biLogger,
                fedops: flowAPI.fedops,
                httpClient: flowAPI.httpClient,
                experiments: flowAPI.experiments,
                customerSubscriptionService: BillingSubscriptions(
                  `${baseUrl}/_api/billing-subscriptions`,
                ).CustomerSubscriptionsService(),
                ecomSubscriptionsService: WixEcommerceSubscriptions(
                  `${baseUrl}/_api/subscriptions-server`,
                ).SubscriptionsApi(),
                memberBenefitsService: PricingPlanBenefitsServer(
                  `${baseUrl}/_api/pricing-plan-benefits`,
                ).MemberBenefits(),
                subscriptionService: SubscriptionsApi(`${baseUrl}/_api/subscriptions-api`).SubscriptionsService(),
              },
            },
          }),
        ],
      });

      store.subscribe(() => {
        setProps({ state: store.getState() });
      });

      setProps({
        methods: {
          ...bindActionCreators(
            {
              fetchAllSubscriptions,
              openDetails,
              closeDetails,
              navigateToStores,
              openCancelConfirmModal,
              closeCancelConfirmModal,
              confirmCancel,
              navigateToPricingPlans,
            },
            store.dispatch,
          ),
        },
        state: store.getState(),
        tabState: TabState.Default,
        ppInstalled,
        storesInstalled,
      });

      store.dispatch(setLanguage(wixCodeApi.site.language));
      store.dispatch(setRegionalSettings(wixCodeApi.site.regionalSettings || wixCodeApi.site.language));
      store.dispatch(
        setUser({
          id: wixCodeApi.user.currentUser.id,
          instance: wixCodeApi.user.currentUser.instance || appParams.instance,
          loggedIn: wixCodeApi.user.currentUser.loggedIn,
        }),
      );

      if (isEditor) {
        return store.dispatch(mockSubscriptions());
      }

      wixCodeApi.user.onLogin(() => {
        store.dispatch(
          setUser({
            id: wixCodeApi.user.currentUser.id,
            instance: wixCodeApi.user.currentUser.instance || appParams.instance,
            loggedIn: wixCodeApi.user.currentUser.loggedIn,
          }),
        );
        store.dispatch(fetchAllSubscriptions());
      });

      return store
        .dispatch(fetchAllSubscriptions())
        .then((action) => {
          const totalSubscriptions = action?.payload ? (action?.payload as any[])?.length : 0;
          // @todo add error reporting to catch why no action
          biLogger.report(
            mySubscriptionsOpened({
              totalSubscriptions,
              referralInfo: 'null',
              tabName: 'null',
            }),
          );
        })
        .catch((e) => {
          biLogger.report(
            mySubscriptionsOpened({
              totalSubscriptions: 0,
              referralInfo: 'null',
              tabName: 'null',
            }),
          );
          flowAPI.reportError(e);
        });
    },
    updateConfig($w, config) {
      const updatedPublicData = config.publicData.COMPONENT || {};
      settingsEventsHandler.notify(updatedPublicData);
    },
  };
};

export default createController;
