import {
  SubscriptionStatus,
  V1Subscription,
  SubscriptionFrequency,
  SubscriptionType,
} from '@wix/ambassador-subscriptions-api/http';
import { PAID_PLANS_APP_DEF_ID } from '../components/MySubscriptions/constants';

export const subscriptionsFixture: V1Subscription[] = [
  {
    id: 'mock-12345',
    name: 'Beginner',
    validFrom: new Date(),
    validUntil: new Date(),
    subscriptionStatus: SubscriptionStatus.ACTIVE,
    subscriptionType: SubscriptionType.ONLINE,
    wixAppId: PAID_PLANS_APP_DEF_ID,
    recurring: true,
    price: {
      currency: 'EUR',
      amount: '15',
    },
    billingProfile: {
      frequency: SubscriptionFrequency.MONTH,
      trial: {
        unit: SubscriptionFrequency.MONTH,
        period: 3,
      },
    },
    policy: {
      cancellableByMember: true,
    },
  },
  {
    id: 'mock-1343142',
    name: 'Pro',
    validFrom: new Date(),
    validUntil: new Date(),
    subscriptionStatus: SubscriptionStatus.ACTIVE,
    wixAppId: PAID_PLANS_APP_DEF_ID,
  },
  {
    id: 'mock-1343124',
    name: 'VIP',
    validFrom: new Date(),
    validUntil: new Date(),
    subscriptionStatus: SubscriptionStatus.EXPIRED,
    wixAppId: PAID_PLANS_APP_DEF_ID,
  },
];
