export const detailsFixture = {
  paymentSubscriptionInfo: {
    totalSuccessfulPayments: 3,
    totalBillingCycles: 6,
    paymentMethod: 'creditCard',
    lastSuccessfullyChargedDate: new Date(),
    nextChargeAttemptDate: new Date(),
    suspendable: true,
  },
};
